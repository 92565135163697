<template>
  <div class="header-comp">
    <r-block
      col
      :gap="2"
    >
      <r-title>{{ title }}</r-title>
      <r-button
        v-if="!noCreateButton"
        type="primary"
        @click="$emit('createHandler')"
      >
        {{ createButtonText }}
      </r-button>
    </r-block>
    <export-button
      v-if="exportUrl"
      name="Реестр актов"
      :uri="exportUrl"
    />
  </div>
</template>

<script>
export default {
  components: { exportButton: () => import('@/components/export-button') },
  props: {
    title: {
      type: String,
      default: 'Компонент'
    },
    createButtonText: {
      type: String,
      default: 'Создать'
    },
    noCreateButton: {
      type: Boolean,
      default: false
    },
    exportUrl: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.header-comp {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  background-color: var(--bg_panel_primary);
  border-bottom: 1px solid var(--dividers_low_contrast);
  padding: 0 0.5rem;
  justify-content: space-between;
  grid-gap: 0.5rem;
}
</style>
